'use strict';

Gri.module({
  name: 'section-eta',
  ieVersion: null,
  $el: $('.section-eta'),
  container: '.section-eta',
  fn: function () {

    const $this = this.$el;

    $(window).on('resize', resizeController);
    resizeController();

    function resizeController() {
      let windowW = $(window).width();

      if(windowW < 768) {
        $this.find('.col-sm-3').addClass('item').removeClass('col-sm-3');
        $this.find('.row').css('margin', '0').addClass('owl-carousel').owlCarousel({
          items: 1,
          lazyLoad: true,
          nav: false,
          dots: true,
          margin: 10
        });
        $this.find('.image-wrapper img').removeClass('lazy').addClass('owl-lazy');
      } else {
        $this.find('.item').addClass('col-sm-3').removeClass('item');
        $this.find('.row').removeClass('owl-carousel').owlCarousel('destroy');
        $this.find('.image-wrapper img').removeClass('owl-lazy').addClass('lazy');
      }
    }

  }
});
